var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"addCustomerModal","title":_vm.$t('customer.popup.title'),"ok-title":_vm.$t('shared.button.submit'),"cancel-title":_vm.$t('shared.button.cancel'),"no-close-on-backdrop":""},on:{"hidden":function($event){return _vm.$emit('close')},"ok":_vm.submitForm}},[_c('validation-observer',{ref:"newCustomerValidation"},[_c('form',{attrs:{"id":"createCustomerForm"},on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('customer.label.name'),"rules":"required|min:3|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"}},[_vm._v(" "+_vm._s(_vm.$t('customer.label.name'))+" ")]),_c('b-form-input',{attrs:{"id":"name","type":"text","minlength":"3","maxlength":"100","placeholder":_vm.$t('customer.label.name'),"autocomplete":"chrome-off"},model:{value:(_vm.newCustomerForm.name),callback:function ($$v) {_vm.$set(_vm.newCustomerForm, "name", $$v)},expression:"newCustomerForm.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('customer.label.street_number'),"rules":"required|min:3|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"addressLine1"}},[_vm._v(" "+_vm._s(_vm.$t('customer.label.street_number'))+" ")]),_c('b-form-input',{attrs:{"id":"addressLine1","type":"text","minlength":"3","maxlength":"100","placeholder":_vm.$t('customer.label.street_number'),"autocomplete":"chrome-off"},model:{value:(_vm.newCustomerForm.addressLine1),callback:function ($$v) {_vm.$set(_vm.newCustomerForm, "addressLine1", $$v)},expression:"newCustomerForm.addressLine1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"4"}},[_c('validation-provider',{attrs:{"name":_vm.$t('customer.label.zip'),"rules":"required|min:3|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"zip"}},[_vm._v(" "+_vm._s(_vm.$t('customer.label.zip'))+" ")]),_c('b-form-input',{attrs:{"id":"zip","type":"text","minlength":"3","maxlength":"10","placeholder":_vm.$t('customer.label.zip'),"autocomplete":"chrome-off"},model:{value:(_vm.newCustomerForm.zip),callback:function ($$v) {_vm.$set(_vm.newCustomerForm, "zip", $$v)},expression:"newCustomerForm.zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"cols":"8"}},[_c('validation-provider',{attrs:{"name":_vm.$t('customer.label.city'),"rules":"required|min:3|max:50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"city"}},[_vm._v(" "+_vm._s(_vm.$t('customer.label.city'))+" ")]),_c('b-form-input',{attrs:{"id":"city","type":"text","minlength":"3","maxlength":"50","placeholder":_vm.$t('customer.label.city'),"autocomplete":"chrome-off"},model:{value:(_vm.newCustomerForm.city),callback:function ($$v) {_vm.$set(_vm.newCustomerForm, "city", $$v)},expression:"newCustomerForm.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":_vm.$t('customer.label.tax-number'),"rules":"min:12|max:12"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"taxNumber"}},[_vm._v(" "+_vm._s(_vm.$t('customer.label.tax-number'))+" ")]),_c('b-form-input',{attrs:{"id":"taxNumber","type":"text","minlength":"3","maxlength":"10","placeholder":"PL0000000000","autocomplete":"chrome-off"},model:{value:(_vm.newCustomerForm.taxNumber),callback:function ($$v) {_vm.$set(_vm.newCustomerForm, "taxNumber", $$v)},expression:"newCustomerForm.taxNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
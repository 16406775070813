<template>
  <div>
    <b-modal
      id="addCustomerModal"
      :title="$t('customer.popup.title')"
      :ok-title="$t('shared.button.submit')"
      :cancel-title="$t('shared.button.cancel')"
      no-close-on-backdrop
      @hidden="$emit('close')"
      @ok="submitForm"
    >

      <validation-observer ref="newCustomerValidation">
        <form
          id="createCustomerForm"
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('customer.label.name')"
                rules="required|min:3|max:100"
              >
                <label for="name">
                  {{ $t('customer.label.name') }}
                </label>
                <b-form-input
                  id="name"
                  v-model="newCustomerForm.name"
                  type="text"
                  minlength="3"
                  maxlength="100"
                  :placeholder="$t('customer.label.name')"
                  autocomplete="chrome-off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('customer.label.street_number')"
                rules="required|min:3|max:100"
              >
                <label for="addressLine1">
                  {{ $t('customer.label.street_number') }}
                </label>
                <b-form-input
                  id="addressLine1"
                  v-model="newCustomerForm.addressLine1"
                  type="text"
                  minlength="3"
                  maxlength="100"
                  :placeholder="$t('customer.label.street_number')"
                  autocomplete="chrome-off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

            <!--
            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('customer.label.street_number')"
                rules="required|min:3|max:100"
              >
                <label for="addressLine2">
                  {{ $t('customer.label.street_number') }}
                </label>
                <b-form-input
                  id="addressLine2"
                  v-model="newCustomerForm.addressLine2"
                  type="text"
                  minlength="3"
                  maxlength="100"
                  :placeholder="$t('customer.label.street_number')"
                  autocomplete="off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
            -->

            <b-col
              cols="12"
              class="pb-1"
            >
              <b-row>
                <b-col
                  cols="4"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('customer.label.zip')"
                    rules="required|min:3|max:10"
                  >
                    <label for="zip">
                      {{ $t('customer.label.zip') }}
                    </label>
                    <b-form-input
                      id="zip"
                      v-model="newCustomerForm.zip"
                      type="text"
                      minlength="3"
                      maxlength="10"
                      :placeholder="$t('customer.label.zip')"
                      autocomplete="chrome-off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="8"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('customer.label.city')"
                    rules="required|min:3|max:50"
                  >
                    <label for="city">
                      {{ $t('customer.label.city') }}
                    </label>
                    <b-form-input
                      id="city"
                      v-model="newCustomerForm.city"
                      type="text"
                      minlength="3"
                      maxlength="50"
                      :placeholder="$t('customer.label.city')"
                      autocomplete="chrome-off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              class="pb-1"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('customer.label.tax-number')"
                rules="min:12|max:12"
              >
                <label for="taxNumber">
                  {{ $t('customer.label.tax-number') }}
                </label>
                <b-form-input
                  id="taxNumber"
                  v-model="newCustomerForm.taxNumber"
                  type="text"
                  minlength="3"
                  maxlength="10"
                  placeholder="PL0000000000"
                  autocomplete="chrome-off"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>

          </b-row>
        </form>

      </validation-observer>

    </b-modal>
  </div>
</template>

<script>
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import {
  BCol, BFormInput, BModal, BRow,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, alphaNum, digits } from '@validations'

export default {
  components: {
    BModal,
    BFormInput,
    BCol,
    BRow,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      alphaNum,
      digits,
      newCustomerForm: cloneNested(this.$store.getters['customer/getCustomerData']),
    }
  },
  computed: {
    statuses() {
      return cloneNested(this.$store.getters['customer/getCustomerStatuses'])
    },
  },
  mounted() {
    this.$bvModal.show('addCustomerModal')
  },
  methods: {
    submitForm(event) {
      event.preventDefault()
      this.$refs.newCustomerValidation.validate()
        .then(valid => {
          if (!valid) {
            return false
          }

          this.$store.dispatch('customer/addCustomer', this.newCustomerForm)
            .then(() => {
              this.$bvModal.hide('addCustomerModal')
              this.$emit('submitted')
              window.toast.notify.success(this.$t('customer.notify.add.success_title'))
            })
            .catch(err => parseRequestError(err))

          return true
        })
    },
  },
}
</script>
